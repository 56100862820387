//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import BenefitsBoomerang from '../../components/marketing/benefitsBoomerang'

const useCaseData = [
  {
    title: 'Drive action with your residents using social',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description: 'Inform residents of community updates with visual templates',
    descriptionColor: 'white-3',
    image: 'industryPropertymanagement',
    iconShow: true,
    iconContext: 'building',
    // iconColor: 'white',
    iconfill: 'white',
    iconcolor: 'transparent',
    // lineClass: 'green-1',
    // bgcolor: 'dkgreen-8',
    // border: 'border-top-2-dkgreen-1',
    bggradient: 'cumulus',
    expandcol: 5,
  },
]
const featureData = {
  title: 'Improve resident communication with compelling social images',
  titlecolor: 'black-4',
  mode: 'triovertical',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Community news',
          titlecolor: 'red-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'red-1',
          iconname: 'newspaper',
          iconcolor: 'red',
          path: '/platform/content-marketing/',
          description:
            'Deliver community news through easily customizable, drag-and-drop visual templates that get noticed',
        },
        {
          title: 'New resident leads',
          titlecolor: 'dkorange-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'dkorange-1',
          iconname: 'people',
          iconcolor: 'dkorange',
          path: '/platform/lead-generation/',
          description:
            'Drive awareness of your property with prospects by customizing visual templates for open houses, model showings and amenity offerings',
        },
        {
          title: 'Resident reviews',
          titlecolor: 'yellow-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'yellow-1',
          iconname: 'clipboard',
          iconcolor: 'yellow',
          path: '/platform/pulse-surveys/',
          description:
            'Promote your property by asking residents to leave positive reviews through drag-and-drop social templates',
        },
      ],
    },
  ],
}
const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - HOA / Property Management - Visual Content Marketing"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how Storylava can help you improve resident communication for HOA / Property Management organization',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium sky-1">
            Improve resident communication with visual social assets
          </h1>
          <p class="display-4">
            Drive engagement and improve communications with residents when you
            stay in touch with compelling visual social assets
          </p>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
